.post-signup-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  background-image: url('/graphic_email.png');
  background-size: auto;
  background-position: top;
  background-repeat: no-repeat;
}

.post-signup-thankyou {
  margin-top: 0.7vh;
  height: 7vh;
}

.post-signup-confirmation {
  color: #b9bcc4;
  height: 4vh;
}

.post-signup-click {
  height: 22vh;
  color: #b9bcc4;
  white-space: pre-wrap;
}

.post-signup-button {
  height: 20vh;
}
