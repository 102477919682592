.back-button-container {
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  /* padding-left: 30px; */
  box-sizing: border-box;
  cursor: pointer;
}

.back-button-container:hover {
  background: var(--backButtonHoverBackground);
}

.back-button-container:active {
  background: var(--backButtonPressedBackground);
  color: var(--backButtonPressedColor);
}

.back-button {
  font-size: 1.2rem;
  display: flex;
  align-items: center;
  color: var(--backButtonColor);
}

.back-button-container:active .back-button {
  color: var(--backButtonPressedColor);
}

.back-button-icon {
  width: 25px;
  height: auto;
  display: flex;
  align-items: center;
}

.back-button-spacer {
  width: 20px;
}
