.edit-modal-container {
  padding: 20px;
  width: 400px;
  font-size: large;
}

.edit-modal-owner {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
}

.edit-modal-body {
  max-height: 600px;
  overflow-y: auto;
}

.edit-modal-name {
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.edit-modal-buttons {
  margin: 10px;
  width: 100%;
  justify-content: flex-end;
  display: flex;
}

.edit-modal-buttons > *:first-child {
  margin-right: 10px;
}

.edit-modal-search {
  margin: 10px 0;
}

.owner-edit-root {
  margin-top: 10px;
}