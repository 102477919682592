:root {
  --smallFontSize: 0.75em;
}

html,
body,
#root {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
}

body {
  font-family: 'Source Sans Pro', sans-serif;
  -webkit-font-smoothing: antialiased;
  background: var(--dashboardMainBodyBackground);
  color: var(--bodyText);
  scroll-behavior: smooth;
}

hr {
  border-bottom: 1px solid var(--lightGrey);
  width: 100%;
}

button > span > span > span {
  font-weight: normal !important;
}

input,
textarea {
  -webkit-appearance: none;
}

.app {
  overflow-y: auto;
  overflow-x: hidden;
  width: 100%;
  height: 100%;
}

.app-navbar {
  height: 50px;
}

.app-body {
  width: 100%;
  height: calc(100% - 50px);
  position: relative;
}

.scrollable-body {
  overflow: auto;
  height: 100%;
  width: 100%;
  transition: all 200ms ease-in-out;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 600;
}

::-webkit-scrollbar-track {
  border-radius: 3px;
  background: var(--neutralLight);
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

::-webkit-scrollbar-thumb {
  background-clip: content-box;
  border-radius: 3px;
  background: var(--themeSecondary);
}

::-webkit-scrollbar-button {
  width: 0;
  height: 0;
  display: none;
}

::-webkit-scrollbar-corner {
  background-color: transparent;
}
