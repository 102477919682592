.account-settings-root {
    padding: 20px;
}
.account-settings-title {
    font-size: x-large;
    margin: 10px 0;
}

.account-settings-property{
    margin-bottom: 10px;
    display: flex;
    align-items: center;
}

.account-settings-property > * {
    margin-right: 10px;
}

.account-settings-name {
    width: 200px;
    text-overflow: ellipsis;
    overflow: hidden;
}

.account-settings-topics > *:first-of-type {
    margin-bottom: 20px;
}

.account-settings-password-container > * {
    margin-top: 20px;
}

.account-settings-email-modal {
    padding: 20px;
}
.account-settings-email-modal > * {
    margin-bottom: 20px;
}