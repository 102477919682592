.firebase-error-display-container {
  padding: 36px;
  min-height: 100px;
  width: 600px;
  max-width: 90vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.firebase-error-display-container > * {
  width: 100%;
  text-align: center;
  font-weight: 400;
}

.firebase-error-message {
  color: var(--errorMessage);
  font-size: 1.6rem;
  margin-bottom: 16px;
}

.firebase-error-advice {
  font-size: 1.2rem;
  margin-bottom: 48px;
}

.firebase-error-buttons {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.firebase-error-buttons > button {
  margin-left: 24px;
  margin-right: 24px;
  padding: 16px 24px;
}