.collapse-icon {
  cursor: pointer;
  display: flex;
  align-items: center;
  transition: all 200ms ease-in-out;
  height: 32px;
}

.collapse-icon.is-flipped {
  transform: rotate(180deg);
}
