.DraftEditor-editorContainer > .public-DraftEditor-content {
  width: auto;
  word-break: break-word;
}

.rich-text-editor {
  position: relative;
}

.rich-text-container {
  width: 100%;
  margin-bottom: 15px;
  border-radius: 0 0 5px 5px;
  box-shadow: 0 6px 11px 0 rgba(99, 160, 136, 0.22);
}

.rich-text-editor-container > .rich-text-editor {
  padding: 10px;
  font-size: 14px;
  border-radius: 0 0 5px 5px;
  border-left: 1px solid rgba(var(--themePrimaryIndividual), 0.2);
  border-bottom: 1px solid rgba(var(--themePrimaryIndividual), 0.2);
  border-right: 1px solid rgba(var(--themePrimaryIndividual), 0.2);
}

.rich-text-editor-container > .rich-text-editor-toolbar {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px;
  box-sizing: border-box;
  background: rgba(var(--themePrimaryIndividual), 0.2);
}

.rich-text-editor-container > .rich-text-editor-toolbar > .rich-text-editor-toolbar-button {
  margin: 0 2px;
  background: transparent;
  box-shadow: none;
}
.rich-text-editor-container > .rich-text-editor-toolbar > .rich-text-editor-toolbar-button.active {
  background: var(--themeSecondary);
}
.rich-text-editor-container > .rich-text-editor-toolbar > .rich-text-editor-toolbar-button:active {
  background: var(--themeSecondary);
}

.rich-text-editor-container > .rich-text-editor-toolbar > .rich-text-editor-toolbar-right {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.rich-text-editor-blockquote {
  padding: 10px;
  background: #ccc;
}

.rich-text-editor-left > * {
  text-align: left;
}

.rich-text-editor-center > * {
  text-align: center;
}

.rich-text-editor-right > * {
  text-align: right;
}

.rich-text-editor-link-modal-top {
  height: 40px;
  display: flex;
  align-items: center;
}

.rich-text-editor-link-modal-top-close > button {
  background: transparent;
  box-shadow: none;
}

.rich-text-editor-link-modal-top-close > button:hover {
  background: transparent;
  box-shadow: none;
}

.rich-text-editor-link-modal-top-close > button:active {
  background: transparent;
  box-shadow: none;
}

.rich-text-editor-link-modal-top-save {
  padding-top: 10px;
  padding-right: 10px;
}

.rich-text-editor-link-modal-top > div {
  width: 50%;
}

.rich-text-editor-link-modal-top > div:last-child {
  text-align: right;
}

.rich-text-editor-link-modal-container {
  padding: 36px;
}

.rich-text-editor-link {
  color: var(--themeSecondary);
}
