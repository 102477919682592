.topics-container {
  width: 100%;
  height: 100%;
  align-items: flex-end;
}

.topics-body {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: 24px 0;
  background: var(--dashboardMainBodyBackground);
}

.topics-input-list {
  padding: 0;
}

.topics-input-list > .topics-input {
  display: flex;
  margin-top: 1em;
  list-style: none;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
