.signup-inner {
  background-color: var(--white);
  max-width: 1300px;
  width: 100%;
  height: 100%;
}

.signup-container-top {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: calc(100% - 100px);
  margin: 60px auto 60px auto;
}

.signup-container-top.post-survey-text {
  flex-direction: row;
  align-items: center;
}

.signup-form-text-lg {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  font-size: 3.5em;
  font-weight: 300;
  white-space: pre-wrap;
}

.signup-title {
  text-align: left;
}

.signup-form-text-sm {
  width: 100%;
  line-height: 1.4;
  font-size: 1em;
  flex-wrap: wrap;
}

.mobile-data-header {
  width: 100%;
  font-size: 1.2em;
  padding-bottom: 0px;
  padding-top: 15px;
  box-sizing: border-box;
  text-align: left;
}

.signup-form-text-md {
  font-size: 1.75em;
  white-space: pre-wrap;
}

.signup-form-hr {
  width: 1000px;
  max-width: 1200px;
}

.signup-form-spacer-div {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 25px;
}

.signup-outer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  background-color: var(--bodyBackgroundSecondary);
}

.signup-footer-container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
  position: absolute;
  bottom: 0;
  text-align: left;
  white-space: pre-wrap;
  font-size: 0.75em;
  padding-top: 70px;
  padding-left: 150px;
  padding-bottom: 40px;
  font-size: 1em;
}

.footer-note {
  font-weight: bold;
  padding-bottom: 10px;
  box-sizing: border-box;
}

.main-wrap {
  width: 100%;
  box-sizing: border-box;
}

.mobile-content {
  display: none;
}

.post-survey-signup {
  width: 100%;
  max-width: 600px;
  display: flex;
  justify-content: center;
  text-align: left;
}

.post-survey-signup-title {
  width: 400px;
  text-align: left;
  font-size: 4em;
  font-weight: 300;
  white-space: pre-wrap;
  margin: 0;
}

.post-survey-survey-card {
  width: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  background: var(--themePrimary);
  color: var(--white);
  padding: 20px;
  border-radius: 10px;
}

.post-survey-survey-card > .post-survey-survey-card-title {
  text-align: left;
  font-size: 1.7em;
  margin-top: 25px;
  margin-bottom: 30px;
}

.post-survey-survey-card > button {
  background: var(--themeSecondary);
  padding: 25px 30px;
}

.post-survey-info {
  width: 100%;
  max-width: 600px;
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: left;
}

.post-survey-info > .post-survey-info-title {
  width: 100%;
  font-size: 20px;
  font-weight: normal;
}

.post-survey-info > .post-survey-info-box {
  width: 100%;
  max-width: 600px;
  box-sizing: border-box;
  margin: 0;
  padding: 20px 40px 20px 40px;
  text-align: left;
  line-height: 1.43;
  background: var(--bodyBackgroundSecondary);
  box-shadow: 0 6px 11px 0 rgba(99, 160, 136, 0.3);
  letter-spacing: 0.17px;
}

.post-survey-info > .post-survey-info-box > li {
  margin-bottom: 5px;
}

@media screen and (max-height: 750px) {
  .signup-footer-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: relative;
    bottom: 0;
    text-align: left;
    white-space: pre-wrap;
    padding-top: 50px;
    padding-left: 150px;
    padding-bottom: 40px;
    font-size: 1em;
  }
}

/* Tablet specific */
@media screen and (max-width: 1100px) {
  .signup-container-top {
    width: calc(100% - 50px);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex-wrap: wrap;
    box-sizing: border-box;
    margin-top: 30px;
    margin-bottom: 60px;
  }

  .signup-container-top.post-survey-text {
    flex-direction: column;
    align-items: center;
  }

  .post-survey-signup-title {
    width: 100%;
    font-size: 2.5em;
  }

  .signup-title {
    white-space: pre-wrap;
  }

  .signup-form-text-lg {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    font-size: 2em;
    font-weight: 300;
    white-space: normal;
    margin-bottom: 10px;
    text-align: left;
    width: 100%;
  }

  .signup-form-text-sm {
    font-size: 1em;
    width: 100%;
  }

  .signup-outer {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
    box-sizing: border-box;
    background-color: var(--white);
  }
  .signup-form-hr {
    width: 80%;
    max-width: 1200px;
  }
  .signup-footer-container {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-end;
    position: absolute;
    bottom: 0;
    text-align: left;
    white-space: pre-wrap;
    font-size: 0.75em;
    padding-top: 70px;
    padding-left: 80px;
    padding-bottom: 40px;
    font-size: 1em;
  }

  .mobile-content {
    display: none;
  }
}

/* Phone specific */
@media screen and (max-width: 725px) {
  .signup-container-top {
    margin-bottom: 20px;
  }

  .post-survey-signup-title {
    font-size: 1.7em;
  }

  .post-survey-info > .post-survey-info-box {
    padding: 10px 10px 10px 20px;
  }

  .signup-footer-container {
    display: none;
  }

  .mobile-content {
    display: block;
  }
}
