.profile-editor-title {
  padding-top: 38px;
  padding-bottom: 38px;
  padding-left: 48px;
  padding-right: 48px;
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;
  font-size: 18px;
  font-weight: 600;
}

.profile-editor-topics {
  width: 100%;
  max-width: 560px;
  margin-left: auto;
  margin-right: auto;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}

.profile-editor-topics > *{
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 12px;
  margin-top: 12px;
}

.profile-editor-save {
  margin-top: 36px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media(max-width:725px) {
  .profile-editor-topics {
    grid-template-columns: repeat(2, 1fr);
  }

  .profile-editor-title {
    padding-left: 24px;
    padding-right: 24px;
    padding-top: 12px;
    padding-bottom: 12px;
  }
}