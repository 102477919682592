.respondent-analyze-body {
  width: 100%;
  max-width: 720px;
  margin: 0 auto;
      display: flex;
    flex-direction: column;
    align-items: center;
}

.respondent-analyze-header {
  width: 100%;
  max-width: 720px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.respondent-analyze-title {
  width: 100%;
  margin: 0 auto;
  padding: 15px 0 0 0;
    font-weight: 600;
  font-size: 20px;
  color: #412a84;
}

.respondent-analyze-subtitle {
  font-size: 2rem;
  text-align: center;
  margin: 0;
}

.respondent-analyze-share-thumb {
  width: 100%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  margin-top: 40px;
  box-shadow: var(--containerShadow);
  box-sizing: border-box;
}

.respondent-analyze-share-thumb > .respondent-analyze-share-button-container {
  display: flex;
  justify-content: center;
}

.respondent-analyze-share-thumb .respondent-analyze-share-button {
  margin-top: 10px;
  padding: 22px 60px;
  font-size: 20px;
}

.respondent-analyze-share-thumb > .respondent-analyze-share-info {
  text-align: center;
  line-height: 2rem;
  font-size: 20px;
  margin: 0 0 25px 0;
}

.respondent-analyze-share-modal .ms-Modal-scrollableContent {
  height: 100%;
}

.respondent-analyze-share-modal .respondent-analyze-share-modal-header {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.respondent-analyze-share-modal .respondent-analyze-share-modal-content {
  text-align: center;
  font-size: 20px;
}

.respondent-analyze-share-modal .respondent-analyze-share-modal-icons {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin-top: 25px;
}

.respondent-analyze-share-modal .respondent-analyze-share-modal-header > span {
  width: 50px;
  height: 50px;
}

.respondent-analyze-pagination-group {
  width: 300px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  margin-top: 16px;
}

.respondent-analyze-pagination-group > button:first-child {
  width: 50%;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  margin-right: 5px;
}

.respondent-analyze-pagination-group > button:last-child {
  width: 50%;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  margin-left: 5px;
}

.respondent-analyze-pagination-group > button:only-child {
  width: 260px;
  border-radius: 25px;
  margin: 0;
}

.respondent-analyze-footer-button {
  padding-top: 10px;
}

  .analyze-question-thumb-outer {
    width: 500px;
    min-width: 500px;
  }

@media screen and (max-width: 685px) {
  .respondent-analyze-share-thumb {
    margin: 40px 25px 0 25px;
  }
}

@media screen and (max-width: 835px) {
  .respondent-analyze-header > .respondent-analyze-header-button {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .respondent-analyze-body {
    display: flex;
    flex-direction: column;
  }

  .analyze-question-thumb-outer {
    /* width: 400px;
    min-width: 400px; */
    width: calc(100vw - 10px);
    min-width: calc(100vw - 10px);
  }
}
