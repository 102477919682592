.rectangle-toggle-unchecked,
.rectangle-toggle-checked {
  width: 156px;
  height: 70px;
  padding: 10px 5px;
  border-radius: 8px;
  font-size: 20px;
  font-weight: 400;
  box-sizing: border-box;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  user-select: none;
  transition: all 200ms ease-in-out;
}

.rectangle-toggle-checked {
  background-color: var(--themeDark);
  color: var(--white);
  box-shadow: var(--thumbShadow);
}

.rectangle-toggle-unchecked {
  color: var(--themeDark);
  border: 2px solid var(--themePrimary);
}

.pill-toggle-checked,
.pill-toggle-unchecked {
  width: 140px;
  height: 40px;
  border-radius: 20px;
  font-size: 20px;
  font-weight: 400;
  box-sizing: border-box;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  user-select: none;
  transition: all 200ms ease-in-out;
  box-shadow: var(--thumbShadow);
}

.pill-toggle-unchecked {
  background-color: var(--themePrimary);
  color: var(--white);
}

.pill-toggle-checked {
  background-color: var(--themeDark);
  color: var(--themeSecondary);
}

.pill-toggle-unchecked:hover {
  background-color: var(--themeSecondary);
}

.pill-toggle-checked:hover {
  color: var(--white);
}