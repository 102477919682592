.contacts-container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.contacts-title {
  font-size: 2rem;
  font-weight: lighter;
  margin-top: 15px;
}

.contacts-body {
  height: 100%;
  padding: 10px 0;
  background-color: var(--dashboardMainBodyBackground);
}

.contacts-row {
  background: white;
  box-shadow: 0 1.6px 3.6px 0 rgba(0, 0, 0, 0.132), 0 0.3px 0.9px 0 rgba(0, 0, 0, 0.108);
  padding: 20px 0;
  max-width: 100%;
  cursor: auto;
  user-select: text;
}

.contacts-row .ms-DetailsRow-cell {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.contacts-row .ms-DetailsRow-cell:first-child {
  padding-left: 30px;
}

.contacts-body .ms-DetailsHeader-cellTitle {
  justify-content: flex-start;
  display: flex;
}

.contacts-row .contacts-cell {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 100%;
}

.contacts-row * {
  color: var(--bodyText) !important;
}

.contacts-row .contacts-cell:not(:last-child) {
  margin-bottom: 10px;
}

.contacts-row-inner {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.contacts-row:hover {
  background: white;
}

.contacts-row:active {
  background: white;
}

.contacts-row:focus {
  background: white;
}

.contacts-row:focus:hover {
  background: white;
}

.test-classname {
  background-color: red;
}

.contacts-header {
  padding: 1% 5%;
}

.contacts-header-right {
  display: flex;
  align-items: flex-end;
  width: 100%;
  flex-direction: column;
}

.contacts-header-right > *:not(:last-child) {
  margin-bottom: 20px;
}

.paginator-contacts-container .paginator {
  justify-content: center;
  margin: 20px 0;
}