.search-input {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  box-sizing: border-box;
}

.search-input .search-input-title {
  width: 100%;
  height: 32px;
  font-weight: 300;
  display: flex;
  align-items: center;
  margin: 0;
}
