.survey-contacts-title {
  font-weight: normal;
}

.survey-contacts-table .survey-contacts-table-row {
  cursor: auto;
  user-select: text;
  background: var(--white) !important;
  box-shadow: 0 1.6px 3.6px 0 rgba(0, 0, 0, 0.132), 0 0.3px 0.9px 0 rgba(0, 0, 0, 0.108);
}

.survey-contacts-table .survey-contacts-table-row .ms-DetailsRow-cell {
  color: var(--black) !important;
}

.survey-contacts-table .survey-contacts-table-row .ms-DetailsRow-cell:last-child {
  justify-content: center;
  /* padding-right: 30px; */
}

.survey-contacts-filters {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.survey-contacts-filters button {
  min-width: fit-content;
}


.survey-contacts-filters > button:not(:last-child) {
  margin-right: 24px;
}

.survey-contacts-table .ms-List-cell:nth-child(even) .ms-DetailsRow-fields {
  background: #f0faf4;
}

.survey-contacts-table .ms-DetailsRow {
  margin: 0;
  border-radius: 0;
}