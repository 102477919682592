.importance-slider-container {
  width: 350px;
}

.ms-Slider-thumb {
  width: 100%;
  border-radius: 0px 50% 50% 50%;
  top: 7px;
  height: 24px;
  width: 24px;
  transform: rotate(45deg);
  margin-left: -12px;
}

.slider-steps-dots,
.slider-steps-numbers {
  pointer-events: none;
  top: 0;
  left: -4px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.slider-steps-dots {
  width: calc(100% + 5px);
  position: absolute;
  height: 14px;
}

.slider-steps-numbers {
  width: calc(100% + 9px);
  position: relative;
  z-index: 0;
  margin-top: 4px;
  font-size: 0.8em;
}

.slider-step {
  background-color: var(--bodyText);
  border-radius: 50%;
  width: 6px;
  height: 6px;
}

@media only screen and (max-width: 725px) {
  .importance-slider-container {
    width: calc(100% - 20px);
  }
}
