.spin-box {
  position: absolute;
  margin: auto;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  border-radius: 100%;
  width: calc(var(--loadingSpinnerSize) * 1px);
  height: calc(var(--loadingSpinnerSize) * 1px);
  box-shadow: calc(var(--loadingSpinnerSize) * 1px) calc(var(--loadingSpinnerSize) * 1px) var(--loadingSpinnerColor2),
    calc(var(--loadingSpinnerSize) * -1px) calc(var(--loadingSpinnerSize) * 1px) var(--loadingSpinnerColor1),
    calc(var(--loadingSpinnerSize) * -1px) calc(var(--loadingSpinnerSize) * -1px) var(--loadingSpinnerColor2),
    calc(var(--loadingSpinnerSize) * 1px) calc(var(--loadingSpinnerSize) * -1px) var(--loadingSpinnerColor1);
  animation: loading ease infinite 4s;
}

@keyframes loading {
  0%,
  100% {
    box-shadow: calc(var(--loadingSpinnerSize) * 1px) calc(var(--loadingSpinnerSize) * 1px) var(--loadingSpinnerColor2),
      calc(var(--loadingSpinnerSize) * -1px) calc(var(--loadingSpinnerSize) * 1px) var(--loadingSpinnerColor1),
      calc(var(--loadingSpinnerSize) * -1px) calc(var(--loadingSpinnerSize) * -1px) var(--loadingSpinnerColor2),
      calc(var(--loadingSpinnerSize) * 1px) calc(var(--loadingSpinnerSize) * -1px) var(--loadingSpinnerColor1);
  }

  25% {
    box-shadow: calc(var(--loadingSpinnerSize) * -1px) calc(var(--loadingSpinnerSize) * 1px) var(--loadingSpinnerColor1),
      calc(var(--loadingSpinnerSize) * -1px) calc(var(--loadingSpinnerSize) * -1px) var(--loadingSpinnerColor2),
      calc(var(--loadingSpinnerSize) * 1px) calc(var(--loadingSpinnerSize) * -1px) var(--loadingSpinnerColor1),
      calc(var(--loadingSpinnerSize) * 1px) calc(var(--loadingSpinnerSize) * 1px) var(--loadingSpinnerColor2);
  }

  50% {
    box-shadow: calc(var(--loadingSpinnerSize) * -1px) calc(var(--loadingSpinnerSize) * -1px) var(--loadingSpinnerColor2),
      calc(var(--loadingSpinnerSize) * 1px) calc(var(--loadingSpinnerSize) * -1px) var(--loadingSpinnerColor1),
      calc(var(--loadingSpinnerSize) * 1px) calc(var(--loadingSpinnerSize) * 1px) var(--loadingSpinnerColor2),
      calc(var(--loadingSpinnerSize) * -1px) calc(var(--loadingSpinnerSize) * 1px) var(--loadingSpinnerColor1);
  }

  75% {
    box-shadow: calc(var(--loadingSpinnerSize) * 1px) calc(var(--loadingSpinnerSize) * -1px) var(--loadingSpinnerColor1),
      calc(var(--loadingSpinnerSize) * 1px) calc(var(--loadingSpinnerSize) * 1px) var(--loadingSpinnerColor2),
      calc(var(--loadingSpinnerSize) * -1px) calc(var(--loadingSpinnerSize) * 1px) var(--loadingSpinnerColor1),
      calc(var(--loadingSpinnerSize) * -1px) calc(var(--loadingSpinnerSize) * -1px) var(--loadingSpinnerColor2);
  }
}
