:root {
  --lightGrey: #b7b8b8;
  --midGrey: #5d6067;
  --darkGrey: #31343b;
  --almostBlack: #17181c;
  --red: #f54d41;
  --black: #000;
  --white: #fff;
  --errorRed: #c93b22;

  /* PALETTE COLORS */
  --themePrimaryIndividual: 165, 214, 186;
  --themePrimary: #412a84;
  --themeSecondary: #22c960;
  --themeTertiary: #4d6e5d; /* need tertiary green color */
  --themeDark: #283b32;
  --themeDisabled: #adbcb4;
  --themePurple: #412a84;
  --themePurpleLighter: #5627d8;
  --themePrimaryDisabled: #b1adbc;
  --themeSecondaryDisabled: var(--themeDisabled);

  /* Interactive states */
  --themePrimaryActive: #283b32;
  --themePrimaryHover: #22c960;

  --themeLighterAlt: #c6c7cc;
  --neutralLight: #e0e6e4;
  --neutralLighter: #e9efeb;

  /* SEMANTIC COLORS */
  --bodyBackground: var(--white);
  --bodyBackgroundSecondary: #e9f0ec;
  --bodyFrameBackground: var(--white);
  --bodyFrameBackgroundHover: var(--white);
  --bodyText: var(--themePrimaryActive);

  /* colors for custom components */
  --navbar: linear-gradient(90deg, var(--themeSecondary) 0%, var(--themePrimary) 100%);
  --pvmLogo: var(--white);

  /* Survey Respondent View */
  --surveyImportantTrayCollapsed: #e9f0ec;
  --surveyImportantTrayCollapsedHeader: var(--themePrimary);
  /* Survey Creation */
  --questionsBackground: var(--neutralLighter);
  --topicGroup: var(--themePrimary);
  --topicGroupActive: var(--themeSecondary);
  --topicGroupShadow: 0 7px 10px 1px #62a08760;

  --questionHeaderBackground: rgb(var(--themePrimaryIndividual));

  /* --containerShadow: 0px 5px 20px 1px #00000040; */
  --containerShadow: 0 2px 24px 0 rgba(0, 0, 0, 0.13);

  /* Dashboard Menu */
  --dashboardMenuBackground: var(--themeTertiary);
  --dashboardMenuItem: var(--white);
  --dashboardMenuBackgroundActive: var(--themeDark);
  --dashboardMenuItemActive: var(--themeSecondary);
  --dashboardMenuBackgroundHover: var(--themeSecondary);
  --dashboardMenuItemHover: var(--white);

  /* Dashboard Items */
  --dashboardMainBodyBackground: var(--neutralLighter);
  --thumbBackground: rgb(var(--themePrimaryIndividual));
  --thumbBackgroundHover: var(--themeSecondary);
  --thumbShadow: 0px 6px 11px #63a0884c;

  /* Analyze */
  --analyzeThumbShadow: var(--containerShadow);
  --analyzeThumbBackground: var(--white);

  /* Button */
  --button: var(--themePrimary);
  --buttonHover: var(--themeSecondary);
  --buttonActive: var(--themeDark);
  --buttonText: var(--white);
  --buttonPrimaryShadow: 0 6px 11px 0 rgba(65, 42, 132, 0.3);
  --buttonSecondaryShadow: 0 6px 11px 0 rgba(34, 201, 96, 0.3);
  --buttonPressedShadow: 0 6px 11px 0 rgba(40, 59, 50, 0.3);
  --buttonPrimaryDisabledShadow: 0 6px 11px 0 rgba(177, 173, 188, 0.3);
  --buttonSecondaryDisabledShadow: 0 6px 11px 0 rgba(173, 188, 180, 0.3);

  --dropAreaBackground: #41e09844;
  --dropAreaColor: var(--themeTertiary);

  --link: var(--themePrimary);
  --linkHover: var(--themeSecondary);
  --linkActive: var(--themeTertiary);

  --checkboxBorder: var(--lightGrey);
  --checkboxHoverBorder: var(--themeSecondary);
  --checkboxChecked: var(--themeSecondary);
  --checkboxCheckedHover: var(--themeSecondary);
  --checkboxColor: var(--white);

  --menuItemColor: var(--themeDark);
  --menuItemHover: var(--neutralLighter);
  --menuItemPress: var(--neutralLight);

  --iconButtonColor: var(--almostBlack);

  --dropdownItemColor: var(--almostBlack);

  --textFieldBackground: var(--white);
  --textFieldShadow: inset 0px 0px 3px 1px rgba(49, 52, 59, 0.4);

  --errorMessage: var(--red);

  --scrollThumb: var(--themePrimary);

  --backButtonColor: var(--white);
  --backButtonHoverBackground: var(--themeSecondary);
  --backButtonPressedBackground: var(--themeDark);
  --backButtonPressedColor: var(--themeSecondary);

  --toggleThumbChecked: var(--themeSecondary);
  --togglePillChecked: var(--themeSecondary);
  --toggleThumbBorderChecked: 1px solid #e9f0ec;

  --toggleThumb: var(--themeDark);
  --togglePill: var(--themeDark);
  --toggleThumbBorder: 1px solid #e8efeb;

  --detailsRowBackground: #a5d6ba;
  --detailsRowBackgroundHover: var(--themeSecondary);
  --detailsRowBackgroundActive: var(--themeDark);
  --detailsCellColor: var(--black);
  --detailsCellColorActive: var(--themeSecondary);

  --toggleThumbShadow: 0px 0px 10px 1px #0004;

  --primaryRadius: 5px;
  --createSurveyModalMaxWidth: 800px;
  --sendSurveyMaxWidth: 1500px;
  /* widths set to design sheet specs */
  --dashboardBodyMaxWidth: 1745px;
  --dashboardMenuWidth: 175px;

  --loadingSpinnerSize: 15;
  --loadingSpinnerColor1: #6f9c84;
  --loadingSpinnerColor2: #422985;

  --notificationError: var(--errorRed);
  --notificationNormal: var(--themePurpleLighter);

  --respondentThumbNew: #a5d6ba;
  --respondentThumbNewHover: #22c960;
  --respondentThumbNewActive: #283b32;
  --respondentThumbNewActiveTitle: #22c960;

  --respondentThumbStarted: #94adcb;
  --respondentThumbStartedTitle: #11072a;
  --respondentThumbStartedHover: #2067bd;
  --respondentThumbStartedActive: #313f54;
  --respondentThumbStartedActiveTitle: #94adcb;

  --respondentThumbCompleted: #92c2bc;
  --respondentThumbCompletedTitle: #11072a;
  --respondentThumbCompletedHover: #20baa7;
  --respondentThumbCompletedActive: #26403d;
  --respondentThumbCompletedActiveTitle: #92c2bc;
  --defaultUserIconBackground: #ebf2ef;
}
