.cbx-container {
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
}

.cbx-container * {
  box-sizing: border-box;
}

.cbx {
  -webkit-user-select: none;
  user-select: none;
  cursor: pointer;;
  padding: 6px 8px;
  border-radius: 6px;
  overflow: hidden;
  transition: all .2s ease;
}

.cbx:not(:last-child) {
  margin-right: 6px;
}

.cbx span {
  float: left;
  vertical-align: middle;
  transform: translate3d(0,0,0);
}

.cbx span:first-child {
  position: relative;
  width: 20px;
  height: 20px;
  border-radius: 6px;
  transform: scale(1);
  border: 2px solid var(--lightGrey);
  transition: all .2s ease;
  box-shadow: 0 1px 1px rgba(#00104B,.05);
}

.cbx span:first-child svg {
  position: absolute;
  top: 3px;
  left: 2px;
  fill: none;
  stroke: #FFFFFF;
  stroke-width: 2;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-dasharray: 16px;
  stroke-dashoffset: 16px;
  transition: all .3s ease;
  transition-delay: .1s;
  transform: translate3d(0,0,0);
}

.cbx span:last-child {
  padding-left: 8px;
  line-height: 18px;
}

.cbx:hover span:first-child {
  border-color: var(--themeSecondary);
}

.inp-cbx:disabled + .cbx span:first-child {
  border-color: var(--themeDisabled);
}

.inp-cbx {
  position: absolute;
  visibility: hidden;
}

.inp-cbx:checked + .cbx span:first-child {
  background: var(--themeSecondary);
  border-color: var(--themeSecondary);
  animation: wave .4s ease;
}

.inp-cbx:checked + .cbx span:first-child svg {
  stroke-dashoffset: 0;
}

.inp-cbx:checked:disabled + .cbx span:first-child {
  background: var(--themeDisabled);
  border-color: var(--themeDisabled);
}

@keyframes wave {
  50% {
    transform: scale(.9);
  }
}

.inline-svg {
  position: absolute;
  width: 12px;
  height: 10px;
  pointer-events: none;
  user-select: none;
}