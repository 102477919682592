.survey-invitations-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 85vw;
  max-width: var(--sendSurveyMaxWidth);
  padding-left: 24px;
  padding-right: 24px;
}

.survey-invitations-title {
  padding-top: 2%;
  padding-bottom: 2%;
  font-size: xx-large;
}

.survey-invitations-searchbar {
  display: flex;
  width: 100%;
  padding-bottom: 2%;
  align-content: center;
}

.survey-invitations-searchbar-left {
  padding-top: 0.5em;
  align-self: flex-start;
  width: 80%;
}

.survey-invitations-searchbar-right {
  width: 20%;
  align-self: flex-end;
}

.survey-invitations-quickfilters-container {
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  align-self: flex-start;
  width: 80%;
  display: flex;
}

.survey-invitations-quickfilters-item {
  padding-right: 10px;
}

.filtertag-text {
  padding-right: 10px;
  padding-left: 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  align-self: center;
  cursor: pointer;
}

.filtertag-container {
  padding: 0px 4px 0px 4px;
  color: var(--white);
  font-size: small;
  font-weight: 600;
  text-align: center;
  display: flex;
  flex-direction: row;
  height: 30px;
  max-width: 200px;
  box-shadow: var(--topicGroupShadow);
  border-radius: 20px;
}

.filtertag-active {
  background-color: var(--topicGroupActive);
}

.filtertag-inactive {
  background-color: var(--topicGroup);
}
