.landing-wrapper {
  width: 100%;
  height: 100%;
  background-color: var(--almostBlack);
  background-image: url(/landing_hero.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.landing-main-logo {
  flex: 1;
  display: flex;
  /* align-items: center; */
  align-items: flex-end;
  justify-content: center;
}

.landing-main-logo-img {
  width: 420px;
  /* margin-bottom: 128px; */
  margin-bottom: 250px;
}

.landing-overlay {
  background-color: #4E6E5DCD;
  min-height: 200px;
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.landing-description {
  max-width: 40vw;
  font-size: 1.8vw;
  font-weight: 300;
  color: var(--white);
  text-align: center;
  user-select: none;
  cursor: default;
  margin-top: 48px;
  margin-bottom: 24px;

  display: flex;
  align-items: center;
  justify-content: center;
}

.landing-participate-button {
  min-height: 48px;
  margin-bottom: 48px;
  margin-top: 24px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.landing-participate-button > button {
  height: 48px;
  font-size: 18px;
  border-radius: 24px;
}

@media(max-width: 1100px) {
  .landing-overlay {
    min-height: 30vw;
  }
  .landing-description {
    font-size: 4vw;
    max-width: 70vw;
    margin-top: 32px;
    margin-bottom: 16px;
  }
  .landing-participate-button {
    margin-bottom: 36px;
  }
  .landing-participate-button > button {
    height: 10vw;
    font-size: 4vw;
    border-radius: 5vw;
  }
  .landing-main-logo-img {
    width: 70%;
  }
}

@media(max-width: 725px) {
  .landing-overlay {
    min-height: 30vw;
  }
  .landing-description {
    font-size: 5vw;
    max-width: 90vw;
    margin-top: 16px;
    margin-bottom: 16px;
  }
  .landing-participate-button {
    margin-top: 16px;
    margin-bottom: 16px;
  }
  .landing-main-logo-img {
    width: 80%;
  }
}

/* 
.landing-slide {
  height: 75%;
  min-width: 100%;
  flex: 1;
  display: flex;
  background-position: center;
  background-repeat: no-repeat;
}

.landing-logo-container {
  height: 75%;
  z-index: 1;
}

.landing-logo {
  position: absolute;
  top: 0;
  max-width: 20%;
  max-height: 20%;
  padding-top: 40px;
  padding-left: 5vh;
}

.landing-bottom-container {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-bottom: 10px;
}

.landing-large-text {
  white-space: pre-wrap;
  text-align: center;
  font-size: xx-large;
  padding-bottom: 1vh;
}

.landing-small-text {
  white-space: pre-wrap;
  padding-bottom: 1vh;
}

.landing-privacy-text {
  color: var(--bodyText);
  margin-top: 20px;
  cursor: pointer;
}

.landing-privacy-text:hover {
  color: var(--themePrimary);
} */
