.send-email {
  min-height: calc(100% - 190px);
  background: var(--white);
  box-shadow: var(--containerShadow);
  box-sizing: border-box;
}

.thumb-container {
  width: 200px;
  height: 200px;
  background: var(--thumbBackground);
  padding: 10px;
  justify-content: space-between;
  cursor: pointer;
}

.thumb-container:hover {
  background: var(--thumbBackgroundHover);
}

.thumb-text-small {
  font-size: 0.8em;
  height: 50px;
  align-items: center;
}

.thumb-center {
  font-size: 1.4em;
  height: 100px;
  justify-content: center;
  align-items: center;
}
.send-container-text {
  font-size: 1.5em;
  margin-bottom: 50px;
}

.thumb-list-container > div:not(:last-child) {
  margin-right: 30px;
}

.send-email-container {
  min-height: 100px;
}
.send-email-container > *:not(:last-child) {
  margin-bottom: 15px;
}

.send-email-container > * {
  width: 100%;
}

.send-email-container-header {
  font-size: 1.2rem;
  font-weight: 400;
  margin: 24px 0;
}
.send-email-container-custom-input.flex-row {
  flex-direction: column;
  justify-content: flex-end;
  margin-bottom: 4px;
}
.send-email-container-custom-input > .send-email-container-custom-input-label {
  display: flex;
}
.send-email-container-custom-input > .send-email-container-custom-input-label > * {
  width: 50%;
}
.send-email-container-custom-input > .send-email-container-custom-input-label > .ms-TooltipHost {
  display: flex;
  justify-content: flex-end;
  padding-bottom: 5px;
}
.send-email-container-custom-input > form {
  display: flex;
  flex-direction: row;
  padding: 5px;
  box-shadow: var(--textFieldShadow);
  border-radius: 3px;
  width: 100%;
}

.send-email-container-upload {
  display: flex;
  justify-content: flex-end;
}

.send-email-container-button {
  justify-content: flex-end;
}
.send-email-param {
  width: 75px;
  display: flex;
  justify-content: flex-end;
  margin-right: 10px;
}

.firebase-error-advice.send-email-no-margin {
  margin-bottom: 20px;
}

.send-survey-invalid-contacts-list {
  display: flex;
  flex-wrap: wrap;
  width: 400px;
  height: 80px;
  padding: 0;
  color: var(--errorMessage);
  text-align: left;
  overflow-y: auto;
  margin-bottom: 100px;
}

.send-survey-invalid-contacts-list li {
  width: 50%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  font-size: 16px;
}

.align-center {
  align-items: center;
}

.input-with-button {
  flex-grow: 2;
  border: none;
  font-weight: 300;
  font-size: 14px;
  box-shadow: none;
}

.input-with-button:focus {
  outline: none;
}
