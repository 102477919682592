.email-form-outer {
  width: 100%;
  min-height: 100%;
  height: 100%;
  background: var(--bodyBackgroundSecondary);
}

.email-form-outer > .email-form {
  overflow-y: auto;
  width: calc(100% - 40px);
  max-width: 1400px;
  min-height: 100%;
  box-sizing: border-box;
  margin: 0 auto;
  background: var(--white);
}

.email-form-outer > .email-form > .email-form-container {
  width: calc(100% - 200px);
  margin: 0 auto;
  background: var(--white);
}

.email-form-outer > .email-form > .email-form-container > .email-form-header {
  display: flex;
  align-items: center;
}

.email-form-outer > .email-form > .email-form-container > .email-form-header > .email-form-header-title {
  width: 50%;
  max-width: 600px;
  font-size: 3em;
  white-space: pre-wrap;
  margin: 0 auto;
}

.email-form-outer > .email-form > .email-form-container > .email-form-header > .email-form-header-title > .email-form-header-title-logo {
  max-width: 400px;
}

.email-form-outer > .email-form > .email-form-container > .email-form-header > .email-form-header-right {
  width: 30%;
  display: flex;
  justify-content: flex-end;
  padding: 10px;
}

.email-form-outer > .email-form > .email-form-container > .email-form-header > .email-form-header-right > .email-form-header-survey-tile {
  width: 260px;
  box-sizing: border-box;
  color: var(--white);
  background: var(--themePrimary);
  text-align: center;
  padding: 25px;
  border-radius: 4px;
}

.email-form-outer
  > .email-form
  > .email-form-container
  > .email-form-header
  > .email-form-header-right
  > .email-form-header-survey-tile
  > h3 {
  padding: 0;
  margin-top: 0;
  margin-bottom: 22px;
}

.email-form-outer .email-form-header-info {
  max-width: 600px;
  margin: 0 auto;
  font-size: 20px;
}

.email-form-outer .email-form-header-info-title {
  font-weight: normal;
  font-size: medium;
  margin-bottom: 0;
}

.email-form-outer .email-form-header-info-box {
  margin-top: 5px;
  font-size: 14px;
  box-sizing: border-box;
  padding: 20px 40px 20px 40px;
  text-align: left;
  line-height: 1.43;
  background: var(--bodyBackgroundSecondary);
  box-shadow: 0 6px 11px 0 rgba(99, 160, 136, 0.3);
  border-radius: 5px;
  letter-spacing: 0.17px;
}

.email-form-outer > .email-form > .email-form-container > .email-form-header {
  margin: 30px 0;
}

.email-form-outer > .email-form > .email-form-container > .email-form-header > *:first-child {
  padding-right: 50px;
}

.email-form-outer > .email-form > .email-form-container > .email-form-header > .email-form-header-title .email-form-header-title-bold {
  font-weight: 600;
}

.email-form-outer > .email-form > .email-form-container > .email-form-data-rights {
      width: calc(100vw - 40px);
  position: absolute;
  bottom: 50px;
}

.email-form-outer > .email-form > .email-form-container > .email-form-data-rights > .email-form-data-rights-title {
  margin-top: 25px;
  margin-bottom: 0;
  font-size: 22px;
  font-weight: normal;
}

.email-form-outer > .email-form > .email-form-container > .email-form-data-rights > .email-form-data-rights-info {
  margin-top: 5px;
}

.tablet-hide {
  display: inherit;
}

.tablet-show {
  text-align: center;
  display: none;
}

.mobile-hide {
  display: inherit;
}

.mobile-show {
  display: none;
}

@media screen and (max-width: 1100px) {
  .email-form-outer > .email-form {
    width: 100%;
  }

  .email-form hr {
    margin-bottom: 50px;
  }

  .email-form-outer > .email-form > .email-form-container > .email-form-header {
    flex-direction: column;
    align-items: flex-start;
  }

  .email-form-outer > .email-form > .email-form-container > .email-form-header > .email-form-header-title {
    width: 100%;
    margin-top: 20px;
    margin-bottom: 10px;
  }

  .email-form-outer > .email-form > .email-form-container > .email-form-header > .email-form-header-title > .email-form-header-title-logo {
    max-width: 200px;
  }

  .email-form-outer > .email-form > .email-form-container > .email-form-header > .email-form-header-info {
    width: 100%;
    font-size: var(--fontSizeSmall);
  }

  .email-form-outer > .email-form > .email-form-container > .email-form-header > *:first-child {
    padding: 0;
  }

  .email-form-outer > .email-form > .email-form-container > .email-form-header > .email-form-header-right {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .tablet-hide {
    display: none;
  }

  .tablet-show {
    display: inherit;
  }
}

@media screen and (max-width: 725px) {
  .email-form-outer > .email-form > .email-form-container {
    width: calc(100% - 80px);
  }

  .email-form-outer > .email-form > .email-form-container > .email-form-header > .email-form-header-title {
    font-size: 1.7em;
  }

  .email-form-outer > .email-form > .email-form-container > .email-form-header > .email-form-header-info > .email-form-header-info-title {
    text-align: center;
    font-size: 1em;
  }

  .email-form-outer > .email-form > .email-form-container > .email-form-data-rights {
        left: 40px;
    width: fit-content;
    bottom: 30px;
    position: static;
    margin-top: 20px;
  }

  .email-form-outer > .email-form > .email-form-container > .email-form-data-rights > .email-form-data-rights-title {
    font-size: 1em;
    margin-top: 0;
  }

  .mobile-hide {
    display: none;
  }

  .mobile-show {
    display: inherit;
  }
}
