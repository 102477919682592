.registration-outer-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.registration-inner-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 80%;
}

.registration-inner-container > * {
  width: 100%;
}

.registration-form-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.registration-form-buttons {
  width: 100%;
  max-width: 600px;
  display: flex;
  flex-direction: row;
  align-self: center;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}

.registration-form-buttons > button {
  width: calc(50% - 10px);
  margin-bottom: 20px;
  font-size: 18px;
  padding: 25px 12px 25px 12px;
  font-weight: lighter;
}

.registration-form-textinput {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.registration-form-title {
  font-size: 2.5em;
  white-space: pre-wrap;
  margin: 0;
  padding-bottom: 40px;
  padding-top: 25px;
}

form {
  width: 100%;
  align-self: center;
}

.form-center {
  width: 100%;
  max-width: 500px;
  align-self: center;
}

.form-button {
  align-self: center;
  margin-top: 18px;
}

.form-right {
  width: 100%;
  margin: 0 auto 10px auto;
  box-sizing: border-box;
  padding-top: 10px;
  color: var(--themePrimaryActive);
  font-size: 1em;
  max-width: 500px;
  text-align: center;
}

.form-right > a {
  font-size: 1em;
  color: var(--themePrimaryActive);
}

.form-right > button {
  font-size: 1em;
  color: var(--themePrimaryActive);
}

.submit-button-content {
  width: 60vw;
  max-width: 270px;
}

@media only screen and (max-width: 1100px) {
  .registration-form-textinput {
    width: 100%;
  }

  .form-center {
    width: 100%;
  }
}

@media only screen and (max-width: 725px) {
  .registration-form-buttons {
    display: flex;
    flex-direction: column;
    align-self: center;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }
  .registration-form-buttons > button {
    width: 100%;
  }

  .form-right {
    width: 100%;
    max-width: 500px;
    text-align: center;
  }

  .registration-form-title {
    font-size: 1.5em;
    white-space: pre-wrap;
    padding-bottom: 5px;
    padding-top: 0px;
  }
}

@media only screen and (max-width: 1100px) and (min-width: 726px) {
  .form-right {
    width: 100%;
    max-width: 500px;
    text-align: center;
  }

  .registration-form-title {
    font-size: 2em;
    white-space: pre-wrap;
    padding-bottom: 25px;
  }
}
