.contacts-picker {
  display: flex;
  flex-direction: column;
}

/* .contacts-picker .ms-Label {
  padding: 5px 0;
  font-weight: 300;
  color: var(--bodyText);
  margin-bottom: 2px;
} */

.contacts-picker-error .ms-BasePicker {
  border: 1px solid var(--errorMessage);
}
