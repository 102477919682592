.dashboard-view-options {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.dashboard-view-options i {
  /* font-size: 30px; */
  display: flex;
  justify-content: center;
  padding: 6px;
  cursor: pointer;
  transition: all 200ms ease-in-out;
  color: var(--white);
  user-select: none;
}

.dashboard-view-options .icon-container {
  width: 61px;
}

.ms-Callout-main {
  border-radius: 6px;
}

.dashboard-body-lower .ms-Viewport {
  width: 100%;
  margin-top: 25px;
}

.list-header-string, .list-header-string .ms-DetailsHeader-cellTitle{
  justify-content: flex-start;
}

.list-header-number, .list-header-number .ms-DetailsHeader-cellTitle{
  justify-content: center;
}

.list-cell-string {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
}

.list-cell-number {
  width: 100%;
  text-align: center;
}

.no-results-found {
  width: 100%;
  margin-top: 20px;
}