.survey-respondentview-container-outer {
  flex: 1 1 auto;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: calc(100% - 38px);
  max-width: var(--dashboardBodyMaxWidth);
  box-sizing: border-box;
  margin-left: auto;
  margin-right: auto;
  overflow: auto;
}

.survey-respondentview-header {
  display: flex;
  flex-direction: column;
  margin: 15px auto 20px auto;
  width: 80%;
}

.survey-respondentview-empty {
  max-width: var(--dashboardBodyMaxWidth);
  width: 80%;
  box-sizing: border-box;
  margin-left: auto;
  margin-right: auto;
  line-height: 30px;
}

.survey-respondentview-empty-buttons {
  margin-top: 30px;
}

.survey-respondentview-empty-buttons > *:first-child {
  margin-right: 10px;
}
.survey-respondentview-progressbar-wrapper {
  position: sticky;
  /* top: 50px; */
  z-index: 1000;
  background: var(--white);
  display: flex;
  justify-content: center;
  align-items: center;
  height: 38px;
  max-width: var(--dashboardBodyMaxWidth);
  box-sizing: border-box;
  margin-left: auto;
  margin-right: auto;
}

.survey-respondentview-progressbar {
  display: flex;
  justify-content: flex-start;
  border-radius: var(--primaryRadius);
  background-color: var(--lightGrey);
  height: 8px;
  margin-bottom: 15px;
  margin-top: 15px;
  width: 80%;
}

.survey-respondentview-progressbar-segment-single {
  border-radius: var(--primaryRadius);
  background-color: var(--themeSecondary);
  height: 8px;
}

.survey-respondentview-progressbar-segment-right-end {
  border-radius: 0px var(--primaryRadius) var(--primaryRadius) 0;
  background-color: var(--themeSecondary);
  height: 8px;
}

.survey-respondentview-progressbar-segment-left-end {
  border-radius: var(--primaryRadius) 0px 0px var(--primaryRadius);
  background-color: var(--themeSecondary);
  height: 8px;
}

.survey-respondentview-progressbar-segment-middle {
  border-radius: 0px;
  background-color: var(--themeSecondary);
  height: 8px;
}

.survey-respondentview-title {
  font-size: 1.5em;
  font-weight: 400;
  margin-bottom: 5px;
      text-overflow: ellipsis;
    overflow: hidden;
}

.survey-respondentview-description {
  font-size: 1em;
}

.survey-respondentview-scrollarea {
  width: 100%;
  display: flex;
  overflow: auto;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.question-respondentview-textfield {
  margin-top: 10px;
  width: 100%;
}

.question-respondentview-question-body {
  margin-bottom: 36px;
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.question-respondentview-question-title {
  font-weight: 600;
  margin-bottom: 10px;
  width: 80%;
}

.question-respondentview-question-title-info {
  margin-top: 10px;
  color: var(--themePrimary);
  font-weight: normal;
}

.question-respondentview-moreinfo-container {
  width: 80%;
  position: relative;
  z-index: 100;
  background-color: var(--surveyImportantTrayCollapsedHeader);
  box-shadow: 0 2px 3px 0 rgba(65, 42, 132, 0.3);
  color: var(--white);
  max-height: 60px;
  line-height: 1.5rem;
  border-radius: var(--primaryRadius);
  display: flex;
  margin-bottom: 0px;
  cursor: pointer;
  user-select: none;
}

.question-respondentview-moreinfo-container.tray-open {
  background-color: var(--themeSecondary);
  box-shadow: 0 2px 3px 0 rgba(34, 201, 96, 0.3);
  color: var(--white);
}

.question-respondentview-info-content {
  position: relative;
  top: -4px;
  display: flex;
  justify-content: flex-start;
  padding: 24px 20px 20px 20px;
  width: 80%;
  background-color: var(--surveyImportantTrayCollapsed);
  border-radius: 0px 0px var(--primaryRadius) var(--primaryRadius);
  box-shadow: 0 3px 6px 0 rgba(40, 59, 50, 0.16);
  box-sizing: border-box;
}

.question-respondentview-info-content-inner {
  width: 100%;
}

.question-respondentview-moreinfo-inner {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
  box-sizing: border-box;
}

.question-respondentview-moreinfo-inner > .question-respondentview-moreinfo-inner-title {
  text-overflow: ellipsis;
  overflow: hidden;
  padding-right: 10px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

@media screen and (max-width: 600px) {
  .question-respondentview-moreinfo-container {
    width: 100%;
    line-height: 1.8em;
    width: 100%;
    border-radius: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .question-respondentview-moreinfo-container:not(.tray-open) {
    background-color: var(--surveyImportantTrayCollapsedHeader);
  }
  .question-respondentview-moreinfo-container.tray-open {
    background-color: var(--themeSecondary);
  }
  .question-respondentview-moreinfo-inner {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 0px;
    margin-right: 0px;
  }
}

.question-respondentview-collapse-button {
  margin-left: auto;
}

.question-respondentview-responses {
  display: flex;
  flex-direction: column;
  justify-content: left;
  /* align-items: center; */
  width: 80%;
}

.question-respondentview-checkbox {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.question-respondentview-checkbox {
  margin-top: 15px;
}

.survey-respondentview-submit {
  width: 35%;
  margin: 20px auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.survey-respondentview-submit-group {
  width: 300px;
  display: flex;
}

.survey-respondentview-submit-group > button {
  width: 50%;
  padding: 20px 16px;
  font-size: 18px;
}

.survey-respondentview-submit-group > button:first-child {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  margin-right: 5px;
}

.survey-respondentview-submit-group > button:last-child {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  margin-left: 5px;
}

.survey-respondentview-submit-group > button:only-child {
  width: 300px;
  border-radius: 25px;
  margin: 0;
}

.survey-respondentview-agreement {
  width: 300px;
  font-size: 15px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 50px;
}

.survey-respondentview-agreement-submit {
  width: 300px;
  margin-top: 10px;
  padding-bottom: 10px;
}

.survey-respondentview-agreement > .survey-respondentview-agreement-submit > button {
  width: 100%;
  font-size: 18px;
}

.more-info {
  cursor: pointer;
}

.importance-slider-container {
  width: fit-content;
  margin-top: 10px;
  margin-left: 8px;
}

.important-scale-label {
  width: 100%;
  margin-top: 15px;
  margin-bottom: 10px;
  margin-left: -4px;
}

.important-scale-bottom-label {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 5px;
  margin-left: -4px;
}

.question-respondentview-scale {
  width: 80%;
  display: flex;
  justify-content: center;
}

@media (max-width: 725px) {
  .survey-respondentview-progressbar,
  .survey-respondentview-header,
  .question-respondentview-question-title,
  .question-respondentview-info-content,
  .question-respondentview-responses,
  .question-respondentview-moreinfo-container,
  .question-respondentview-scale {
    width: 90%;
  }
}

@media (max-width: 600px) {
  .question-respondentview-moreinfo-container,
  .question-respondentview-info-content {
    border-radius: 0;
    width: 100%;
  }
}
