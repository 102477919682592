.create-survey-banner-options {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.create-survey-banner-options .create-survey-banner-options-search {
  width: 100%;
}
