.dashboard-header {
  box-sizing: border-box;
  background: var(--white);
  padding: 20px 0;
  height: 190px;
  /* height: 140px; */
}

.dashboard-header-inner {
  width: 90%;
  max-width: 1350px;
  margin: 0 auto;
}

.dashboard-header > .dashboard-header-inner > .dashboard-content {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
}

.dashboard-header > .dashboard-header-inner > .dashboard-content .dashboard-header-title {
  font-size: 32px;
  font-weight: 600;
  margin: 0;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.dashboard-header > .dashboard-header-inner > .dashboard-content .dashboard-header-description {
  font-weight: 400;
  font-size: 20px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.dashboard-header > .dashboard-header-inner > .dashboard-content > .dashboard-header-editable {
  min-width: calc(100% / 3);
  flex: 1;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  margin: 0;
}

.dashboard-header-toggleable {
  display: flex;
  margin-top: 10px;
  padding-left: 8px;
}

.dashboard-header > .dashboard-header-inner > .dashboard-search {
  display: flex;
  justify-content: flex-end;
  height: 32px;
  margin-bottom: 20px;
}

.dashboard-header > .dashboard-header-inner > .dashboard-search > * {
  width: 50%;
}

.dashboard-header-stats {
  flex: 1;
  display: flex;
  flex-direction: row;
  margin: 0;
  padding: 0;
}

.dashboard-header-stats > .dashboard-header-stat {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  list-style: none;
  text-align: center;
  margin-right: 2rem;
}

.dashboard-header-stats > .dashboard-header-stat > .dashboard-header-stat-title {
  font-size: xx-large;
}

.dashboard-header-stats > .dashboard-header-stat > .dashboard-header-stat-descriptor {
  font-size: small;
}

.dashboard-header-misc {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.dashboard-header-created-surveys {
  display: flex;
}