.container > .respondent-surveys-container {
  width: 80%;
  margin: auto;
  box-sizing: border-box;
}

.container > .respondent-surveys-container > .respondent-surveys-header {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.container > .respondent-surveys-container > .respondent-surveys-header > h1 {
  width: 100%;
  margin: 20px 0;
  color: var(--themePrimaryActive);
}

.container > .respondent-surveys-container > .respondent-surveys-header > .respondent-surveys-header-input {
  margin: 20px 0;
}

.container > .respondent-surveys-container > .respondent-surveys-header > .respondent-surveys-header-search {
  box-sizing: border-box;
  margin-left: 10px;
  background: transparent;
  box-shadow: none;
}

.container > .respondent-surveys-container > .respondent-surveys-body {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(260px, 260px));
  gap: 32px;
  justify-items: center;
  justify-content: center;
}

.container > .respondent-surveys-container > .respondent-surveys-empty {
  font-size: 18px;
  letter-spacing: 0.22px;
}

.container > .respondent-surveys-container > .respondent-surveys-header > .respondent-surveys-header-search:active {
  background: var(--themeLighterAlt);
}

@media screen and (max-width: 697px) {
  .respondent-surveys {
    width: 95%;
  }

  .container > .respondent-surveys-container {
    width: 90%;
  }

  .container > .respondent-surveys-container > .respondent-surveys-body {
    justify-content: center;
  }
}
