.container {
  width: 90%;
  max-width: 1350px;
  margin: 0 auto;
  padding-bottom: 32px;
}

.container.user-container {
  min-height: 100%;
  max-width: 1730px;
  background: var(--white);
  box-sizing: border-box;
}

@media only screen and (max-width: 1100px) {
  .container {
    width: 85%;
  }
}

@media only screen and (max-width: 1025px) {
  .container {
    width: 100%;
  }
}
