.paginator {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.paginator *:not(:last-child) {
  margin-right: 6px;
}

.paginator-number-button {
  color: var(--black);
  font-size: 14px;
  border-radius: 4px;
  min-width: 24px;
  min-height: 24px;
  cursor: pointer;
  user-select: none;
  transition: all 200ms ease-in-out;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  outline: none;
}

.paginator-number-button:focus {
  outline: none;
}

.paginator-number-button:disabled {
  cursor: default;
  opacity: 0.5;
}

.paginator-number-button:hover:not(:disabled) {
  background-color: var(--themeSecondary);
}

.paginator-number-selected {
  background-color: var(--themeSecondary);
}