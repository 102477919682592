.survey-preview-container {
  /* min-height: 800px;
  padding: 50px 30px;
  width: 100%;
  box-sizing: border-box;
  min-width: 600px; */
  display: flex;
  flex-direction: column;
  align-items: center;
}

.survey-preview-title {
  font-size: 2em; 
  margin: 10px;
}

.survey-preview-header > div:nth-of-type(1) {
  font-size: 2.5em;
  margin-bottom: 15px;
}
.survey-preview-header > div:nth-of-type(2) {
  font-size: 1.5em;
  color: var(--lightGrey);
  margin-bottom: 30px;
}

.survey-preview-question-body {
  /* background: var(--themePrimary); */
  box-shadow: var(--containerShadow);
  padding: 30px;
  margin-bottom: 36px;
}

.survey-preview-question-body .response-line {
  margin-bottom: 25px;
}

.survey-preview-question-text {
  font-size: 1.5em;
  margin-bottom: 30px;
}

.survey-preview-question-text > div {
  display: inline;
}

.survey-preview-options {
  margin-bottom: 12px;
}

.survey-preview-filters > div {
  margin-right: 12px;
}

.iterate {
  margin-top: 20px;
  justify-content: flex-end;
}

.iterate > * {
  margin-right: 10px;
}

.submit-survey {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}

.more-info {
  cursor: pointer;
}
