.dashboard-thumb {
  height: 260px;
  width: 260px;
  min-height: 260px;
  min-width: 260px;
  background: var(--thumbBackground);
  cursor: pointer;
  border-radius: var(--primaryRadius);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-shadow: var(--thumbShadow);
}

.dashboard-thumb.respondent-thumb {
  box-sizing: border-box;
  padding: 10px;
  cursor: auto;
}

.dashboard-thumb:hover:not(.respondent-thumb) {
  background: var(--themePrimaryHover);
}
.dashboard-thumb:active:not(.respondent-thumb) {
  background: var(--themePrimaryActive);
  color: var(--themePrimaryHover);
}
.dashboard-thumb:active:not(.respondent-thumb) .dashboard-survey-title {
  color: var(--themePrimaryHover);
}
.dashboard-thumb:active:not(.respondent-thumb) .dashboard-thumb {
  color: var(--white);
}

.dashboard-thumb:active i {
  color: var(--themePrimaryHover);
}

.dashboard-thumb > div {
  display: flex;
}

.dashboard-thumb-top {
  /* justify-content: flex-end; */
  justify-content: space-between;
  padding: 10px 20px 0px 20px;
  /* width: 100%; */
}
.dashboard-thumb-top-type {
  /* font-weight: bold;  */
}

.dashboard-thumb-center {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.dashboard-thumb-center > button {
  padding: 20px 30px;
  font-size: 18px;
  background: var(--themeSecondary);
  line-height: 1.5;
}

.dashboard-thumb-center > button > span > span > span {
  padding: 0;
  margin: 0;
}

.dashboard-survey-title {
  position: relative;
  text-align: center;
  font-size: 1.3em;
  color: var(--themeDark);
  padding: 0 10px;
  box-sizing: border-box;
  overflow: hidden;
  max-width: 220px;
  margin-bottom: 12px;
      max-height: 100px;
    text-overflow: ellipsis;
    word-break: break-word;
}

.survey-responses-text {
  color: var(--white);
}

.dashboard-thumb-bottom {
  height: 60px;
  flex-direction: column;
  padding: 0px 20px 10px 20px;
  font-size: 14px;
}

.dashboard-thumb-bottom.admin {
  height: 80px;
  flex-direction: column;
  padding: 0px 20px 10px 20px;
  font-size: 14px;
}

.dashboard-thumb.respondent-thumb .dashboard-thumb-bottom {
  flex-direction: column;
  justify-content: flex-end;
  padding: 0 10px 0 10px;
  font-size: 14px;
}

.thumb-completion-status {
  text-align: center;
  font-weight: bold;
  color: var(--white);
}

.thumb-date {
  display: block;
  width: 100%;
  color: var(--themeDark);
  display: flex;
}

.thumb-date:not(:last-child) {
  margin-bottom: 2px;
}

.thumb-date-detail {
  display: inline-block;
  width: 105px;
  justify-self: left;
  text-align: left;
  color: var(--themeDark);
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-weight: 600;
  font-size: 14px;
  vertical-align: top;
}

.thumb-date-detail.value {
  display: inline;
  font-weight: 400;
  margin-left: 16px;
}

.dashboard-thumb:active:not(.respondent-thumb) .thumb-date-detail {
  color: var(--themeSecondary);
}

.survey-status {
  padding: 5px;
  text-transform: uppercase;
  /* background: var(--lightGrey); */
  border-radius: 30px;
  color: var(--white);
}

.create-new-survey {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-size: 1.5em;
}

.create-new-survey .ms-Icon {
  font-size: 30px;
}

.dashboard-thumb.respondent-thumb {
  cursor: pointer;
}

.dashboard-thumb.respondent-thumb .dashboard-survey-title {
  color: var(--themeDark);
  font-weight: bold;
  flex: 1;
  overflow: hidden;
}

.dashboard-thumb.respondent-thumb .thumb-completion-status {
  color: var(--themeDark);
  font-weight: bold;
}

.dashboard-thumb.respondent-thumb .ms-Button--primary {
  width: 90%;
  background-color: var(--themePurple);
  box-shadow: 0px 6px 11px #2e15704c;
}

.dashboard-thumb.respondent-thumb .dashboard-thumb-top {
  flex: 1;
}

.dashboard-thumb.respondent-thumb .dashboard-thumb-center {
  flex: 3;
  margin-bottom: 24px;
  overflow: hidden;
}

.dashboard-thumb.dashboard-thumb-new {
  background-color: var(--respondentThumbNew);
}

.dashboard-thumb.dashboard-thumb-new:hover {
  background-color: var(--respondentThumbNewHover);
}

.dashboard-thumb.dashboard-thumb-new:active {
  background-color: var(--respondentThumbNewActive);
}

.dashboard-thumb.dashboard-thumb-new:active .dashboard-survey-title,
.dashboard-thumb.dashboard-thumb-new:active .thumb-completion-status {
  color: var(--respondentThumbNewActiveTitle);
}

.dashboard-thumb.dashboard-thumb-started {
  background-color: var(--respondentThumbStarted);
}

.dashboard-thumb.dashboard-thumb-started:hover {
  background-color: var(--respondentThumbStartedHover);
}

.dashboard-thumb.dashboard-thumb-started:active {
  background-color: var(--respondentThumbStartedActive);
}

.dashboard-thumb.dashboard-thumb-started .dashboard-survey-title,
.dashboard-thumb.dashboard-thumb-started .thumb-completion-status {
  color: var(--respondentThumbStartedTitle);
}

.dashboard-thumb.dashboard-thumb-started:active .dashboard-survey-title,
.dashboard-thumb.dashboard-thumb-started:active .thumb-completion-status {
  color: var(--respondentThumbStartedActiveTitle);
}

.dashboard-thumb.dashboard-thumb-completed {
  background-color: var(--respondentThumbCompleted);
}

.dashboard-thumb.dashboard-thumb-completed .dashboard-survey-title,
.dashboard-thumb.dashboard-thumb-completed .thumb-completion-status {
  color: var(--respondentThumbCompletedTitle);
}

.dashboard-thumb.dashboard-thumb-completed:hover {
  background-color: var(--respondentThumbCompletedHover);
}

.dashboard-thumb.dashboard-thumb-completed:active {
  background-color: var(--respondentThumbCompletedActive);
}

.dashboard-thumb.dashboard-thumb-completed:active .dashboard-survey-title,
.dashboard-thumb.dashboard-thumb-completed:active .thumb-completion-status {
  color: var(--respondentThumbCompletedActiveTitle);
}
