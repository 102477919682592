.animated-submit-button {
  --animatedButtonBg: var(--themeSecondary);
  --animatedButtonSubmittedBg: var(--themePurpleLighter);

  color: var(--white);
  height: 45px;
  border-radius: 26px;
  padding: 0 36px;
  margin: 12px auto;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: 400;
  outline: none;
  border: none;
  cursor: pointer;
  user-select: none;
  box-shadow: var(--buttonShadow);

  background-color: var(--animatedButtonBg);
  transition: background-position 500ms ease-in-out, padding 200ms ease;
  background: linear-gradient(
    to left,
    var(--animatedButtonBg),
    var(--animatedButtonBg),
    var(--animatedButtonSubmittedBg),
    var(--animatedButtonSubmittedBg)
  );
  background-position: 100% 50%;
  background-size: 300% 300%;
}

.animated-submit-button:not(:disabled):hover {
  box-shadow: var(--buttonShadowHover);
  padding: 0 32px;
}

.animated-submit-button:not(:disabled):not(.animated-submit-button-submitted):hover {
  --animatedButtonBg: var(--themePrimary);
}

.animated-submit-button:not(:disabled):not(.animated-submit-button-submitted):active {
  --animatedButtonBg: var(--themeDark);

  box-shadow: var(--buttonShadowActive);
  color: var(--themeSecondary);
}

.animated-submit-button-error {
  --animatedButtonSubmittedBg: var(--errorRed);
}

.animated-submit-button-submitted {
  background-position: 0% 50%;
  cursor: default;
}

.animated-submit-button:disabled {
  --animatedButtonBg: var(--lightGrey);
  cursor: default;
}

.animated-submit-content {
  transition: opacity 250ms ease-in-out;
  font-family: 'Source Sans Pro';
}
