.notification-container {
  width: 100%;
  height: 100%;
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  pointer-events: none;
  overflow: hidden;
}

.notification-inner {
  position: absolute;
  top: 36px;
  right: 0px;
  width: fit-content;
  pointer-events: all;
}

.notification-single {
  transition: all 300ms ease-in-out;
  margin: 6px 0px;
}

.notification-single-end {
  transform: translate(100px, 0);
  opacity: 0;
}

.notification-single-start {
  transform: translate(100px, 0);
  opacity: 0;
}

.notification-message-bar {
  min-width: 360px;
  border-radius: 8px 0 0 8px;
  padding: 16px 24px;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.notification-error {
  background-color: var(--notificationError);
}

.notification-message {
  background-color: var(--notificationNormal);
}

.notification-message-text {
  text-align: center;
  color: var(--white);
  flex: 1;
  line-height: 32px;
}

.notification-start {
  width: 32px;
}

.notification-dismissal {
  width: 32px;
  height: 32px;
}

.notification-dismiss-button {
  color: var(--white);
  font-size: 20px;
  border-radius: 4px;
  width: 100%;
  height: 100%;
  background: transparent;
  cursor: pointer;
  user-select: none;
  transition: all 200ms ease-in-out;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  outline: none;
}

.notification-dismiss-button:hover {
  color: var(--themeSecondary);
}

.notification-dismiss-button:active {
  color: var(--themeDark);
}